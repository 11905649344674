import nav from "./modules/nav";
import header from "./modules/header";
import anchors from "./modules/anchors";
import anim from "./modules/anim";
import slider from "./modules/slider";
import noname from "./modules/noname";
import tooltip from "./modules/tooltip";
import map from "./modules/map";
import filter from "./modules/filter";
import popup from "./modules/popup";
import notice from "./modules/notice";
import form from "./modules/form";

nav();
header();
anchors();
anim();
slider();
noname();
tooltip();
map();
filter();
popup ();
notice();
form();