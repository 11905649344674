//Создаем переменные для экспорта значений в другой модуль
let bigLogoModPos;
let smallLogoModPos;

function header () {
    /**
     * Настройка сдвига логотипа при скролле на планшете и мобильных
     */
    if(window.innerWidth <= 1240) {
        //Находим логотипы на странице
        const logoBig = document.querySelector('.header__logo-mob-big');
        const logoSmall = document.querySelector('.header__logo-mob-small');
        //Получаем позицию маленького логотипа при загрузке страницы
        const logoSmallPos = +window.getComputedStyle(logoSmall).getPropertyValue("bottom").slice(0, -2);

        //При скролле
        document.addEventListener('scroll', ()=> {
            //Двигаем большой логотип вверх в зависимости от проскроленных пикс
            logoBig.style.transform = `translateY(-${window.scrollY}px)`;
            //Двигаем маленький логотип вниз, меняя полученное значение bottom при загрузке
            logoSmall.style.bottom = `${logoSmallPos + window.scrollY}px`;
            
            //Если маленькое лого подвинулось до нужных значений, перестаем это делать
            if(logoSmall.style.bottom.slice(0, -2) > 0 || logoSmall.style.bottom.slice(0, -2) == 0)
                logoSmall.style.bottom = `${0}px`;

            //Для сафари
            //Если пользователь проскроллил лишние пикс вверх позиционируем лого по-дефолту
            if(window.scrollY < 0) {
                logoSmall.style.bottom = logoSmallPos + 'px';
                logoBig.style.transform = `translateY(0)`;
            }

            //Записываем измененную позицию логотипов в объявленные ранее переменные
            //для переиспользования в других модулях
            bigLogoModPos = `translateY(-${window.scrollY}px)`;
            smallLogoModPos = `${logoSmallPos + window.scrollY}px`;
        });
    }
}

export default header;
export {bigLogoModPos, smallLogoModPos};