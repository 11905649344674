function filter() {
    /**
     * Contact page filter
     */
    const contactFilterBtn = document.querySelector('.contact__filter-btn');

    if(contactFilterBtn) {
        const contactWrapper = document.querySelector('.contact');
        const filterBtnTxt = contactFilterBtn.querySelector('span');
        const filterList = document.querySelector('.contact__filter-list');
        const filterCat = document.querySelectorAll('.contact__filter-cat');
        const closeFilter = document.querySelector('.contact__filter-close');
        const workers = document.querySelectorAll('.contact__cards-item');


        //Показываем список отделов
        contactFilterBtn.addEventListener('click', ()=> {
            filterList.classList.toggle('show');
        });


        //Настраиваем фильтрацию контактов
        filterCat.forEach(el=> {
            el.addEventListener('click', function() {
                //Получаем название отдела и вставляем его в кнопку вызова фильтра 
                const filterCatName = this.innerText;
                filterBtnTxt.innerText = filterCatName;

                contactWrapper.classList.add('filter-is-active');

                //Прячем список отделов
                filterList.classList.remove('show');

                //Получаем категорию отдела и показываем только необходимых сотрудников
                const filterCat = this.dataset.category;
                
                workers.forEach(el=> {
                    el.classList.remove('show');

                    if(el.dataset.category === filterCat) 
                        el.classList.add('show');
                });
            });
        });


        //При клике на кнопку сброса
        closeFilter.addEventListener('click', (e)=> {
            //Закрываем список отделов и возвращаем кнопку фильтра в дефолтное состояние
            filterBtnTxt.innerText = 'Выбрать отдел';

            //Прячем обертку с сотрудниками
            workers.forEach(el=> el.classList.remove('show'));

            contactWrapper.classList.remove('filter-is-active');
        });
    }


    /**
     * Catalog page filter
     */
    const catalogFilter = document.querySelector('.catalog__filter');
    const filterCategories = document.querySelectorAll('.filter-input');
    const showFilterBtn = document.querySelector('.catalog__filter-btn');
    const filterBtnLabel = document.querySelector('.catalog__filter-btn-name');
    const filterWrapper = document.querySelectorAll('.catalog__filter-form');

    if(catalogFilter) {
        /**
         * Настройка отображения категории фильтра в кнопке вызова фильтра
         */
        //Проходим по всем фильтрам и добавляем каждому списку порядковый номер
        filterWrapper.forEach((el, i)=> {
            el.dataset.number = i;
        });

        //Анимируем появление фильтра
        showFilterBtn.addEventListener('click', ()=> {
            //Сначала удаляем класс show, а затем его добавляем через n сек
            catalogFilter.classList.remove('show');
            setTimeout(()=>{catalogFilter.classList.add('show')}, 100);
        });

        filterCategories.forEach((el) => {
            el.addEventListener('click', function (e) {
                //Создаем div для кнопки вызова фильтра при первом клике
                if (filterBtnLabel.querySelectorAll('div').length === 1) {
                    filterBtnLabel.innerHTML = ''; // Очищаем существующий div
            
                    //Создаем нужное количество div в кнопке вызова фильтра
                    for (let i = 0; i < filterWrapper.length; i++) {
                        let categoryShell = document.createElement('div');
                        filterBtnLabel.append(categoryShell);
                    }
                }

                //Получаем лейбл и slug выбранной категории
                const categoryLabel = this.dataset.label;
                const categoryId = this.dataset.category;
                
                if(this.classList.contains('catalog__filter-input')) {
                    //Получаем родителя выбранной категории и его порядковый номер
                    const targetFilterWrapper = this.closest('.catalog__filter-form');
                    const num = targetFilterWrapper.dataset.number;
                
                    //Получаем нужный div в кнопке вызова фильтра по порядковому номеру
                    const targetDiv = filterBtnLabel.querySelectorAll('div')[num];

                    //Записываем id выбранной категории в созданный div для переиспользования в дальнейшем
                    targetDiv.dataset.category = categoryId;
            
                    //Логика для обработки кнопки с id "all"
                    if (categoryId === 'all') {
                        //Если кликнули по "Показать все", очищаем текст в div
                        targetDiv.innerText = '';
                    } else {
                        //Записываем текст в div
                        targetDiv.innerText = categoryLabel;
                    }

                    //Проверяем все div: если все пусты, записываем "Показать все" в первый div
                    const allDivsEmpty = Array.from(filterBtnLabel.querySelectorAll('div')).every(
                        (div) => div.innerText.trim() === ''
                    );
            
                    if (allDivsEmpty) {
                        filterBtnLabel.querySelector('div').innerText = 'Показать все';
                    }
            
                    //Обновляем запятые
                    updateSpansWithCommas();
                }
            });
        });


        //Функция для добавления запятых после непустых div, если их два и более
        function updateSpansWithCommas() {
            const spans = filterBtnLabel.querySelectorAll('div');
          
            //Удаляем все существующие запятые
            filterBtnLabel.querySelectorAll('.comma-span').forEach((commaSpan) => commaSpan.remove());
          
            //Считаем количество непустых div
            const nonEmptySpans = Array.from(spans).filter((span) => span.innerText.trim() !== '');
          
            //Если количество непустых div больше или равно двум
            if (nonEmptySpans.length >= 2) {
                nonEmptySpans.forEach((span, index) => {
                    // Добавляем запятую после всех непустых span, кроме последнего
                    if (index < nonEmptySpans.length - 1) {
                        const commaSpan = document.createElement('span');
                        commaSpan.classList.add('comma-span');
                        commaSpan.innerText = ', ';
                        span.after(commaSpan);
                    }
                });
            }
        }


        /**
         * Настройка фильтрации товаров при загрузке страницы
         * Скрипт носит временный характер
         * После подключения к CMS - снести
         */
        //Получаем сохраненную категорию в ссылке
        const link = window.location.href;
        const savedCategory = link.slice(link.lastIndexOf('?') + 1);
        const products = document.querySelectorAll('.catalog__products-item');

        //Проверяем есть ли в ссылке категория
        if(link.includes('?')) {
            //Создаем div в кнопке вызова фильтра
            if (filterBtnLabel.querySelectorAll('div').length === 1) {
                filterBtnLabel.innerHTML = ''; // Очищаем существующий div
        
                //Создаем нужное количество div в кнопке вызова фильтра
                for (let i = 0; i < filterWrapper.length; i++) {
                    let categoryShell = document.createElement('div');
                    filterBtnLabel.append(categoryShell);
                }
            }

            //Получаем первый div в кнопке вызова фильтра
            const targetDiv = filterBtnLabel.querySelectorAll('div')[0];

            //Записываем id выбранной категории в созданный div для фильтрации в дальнейшем
            targetDiv.dataset.category = savedCategory;

            //Создаем пустую строку
            let categoryLabel = '';

            //Проходим по всем инпутам в фильтре
            filterWrapper[0].querySelectorAll('.catalog__filter-input').forEach(el=> {
                //Удаляем у всех состояние checked
                el.removeAttribute('checked');

                //Если категория инпута соответствует категории в ссылке
                if(el.dataset.category === savedCategory) {
                    //Добавляем инпуту атрибут checked и записываем его лэйбл в пустую строку
                    el.setAttribute('checked', true);
                    categoryLabel += el.dataset.label;
                }
            });

            //Записываем лейбл в кнопку вызова фильтра
            targetDiv.innerText = categoryLabel;


            //Фильтрация продуктов по обновленным категориям
            products.forEach((product) => {
                const productCategories = product.dataset.productcategory;
            
                //Сравниваем строки: если совпадают, показываем продукт, иначе скрываем
                if (productCategories.includes(savedCategory)) {
                    product.style.display = 'block';
                } else {
                    product.style.display = 'none';
                }
            });
        }


        /**
         * Настройка фильтрации товаров в каталоге при клике на инпуты фильтра
         * Скрипт носит временный характер
         * После подключения к CMS - снести
         */
        //Добавляем обработчик событий на каждую кнопку фильтра
        filterCategories.forEach((el) => {
            el.addEventListener('click', function () {
                //Получаем значение категории из кнопки, на которую кликнули
                const filterBtnLabelDiv = filterBtnLabel.querySelectorAll('div');
                let targetCategories = [];
            
                //Собираем выбранные категории из дата-атрибутов
                filterBtnLabelDiv.forEach((el) => {
                    targetCategories.push(el.dataset.category);
                });
            
                //Преобразуем массив выбранных категорий в строку, разделенную пробелами
                let targetCategoryString = targetCategories.join(' ');

                //Проверяем, если выбрана категория "all"
                filterCategories.forEach((el)=> {
                    if (el.id === 'all') {
                        //Находим индекс элемента 'all' и удаляем его из массива
                        targetCategories = targetCategories.filter((el) => el !== 'all');
                    
                        //Преобразуем оставшиеся категории в строку
                        targetCategoryString = targetCategories.join(' ');
                    }
                });

                //Фильтрация продуктов по обновленным категориям
                products.forEach((product) => {
                    const productCategories = product.dataset.productcategory;
                
                    //Сравниваем строки: если совпадают, показываем продукт, иначе скрываем
                    if (productCategories.includes(targetCategoryString)) {
                        product.style.display = 'block';
                    } else {
                        product.style.display = 'none';
                    }
                });
            });
        });
    }


    /**
     * Условие для отслеживания клика по ссылкам навигации
     * Скрипт носит временный характер
     * После подключения к CMS - снести
     */
    filterCategories.forEach(el=> {
        el.addEventListener('click', function(e) {
            const categoryId = this.dataset.category;
            
            if(this.classList.contains('header__subnav-link') || this.classList.contains('footer__subnav-link')) {
                e.preventDefault();
                //Переходим на страницу каталога с названием категории
                window.location.href = `./catalog.html?${categoryId}`;
            }
        });
    });
}

export default filter;