function noname() {
    /**
     * Центруем выбранные элементы по вертикали на десктопе
     */
    const centeredEl = document.querySelector('.centered-el');

    if(centeredEl && window.innerWidth >= 1241) {
        const navList = document.querySelector('.header__nav-list');
        const navListH = navList.offsetHeight / 2;
        const viewportCenter = window.innerHeight / 2;

        centeredEl.classList.contains('centered-el-margin') 
            ? centeredEl.style.marginTop = (viewportCenter - navListH) - 18 + 'px' 
            : centeredEl.style.paddingTop = (viewportCenter - navListH) - 18 + 'px';
    }


    /**
     * Задаем высоту изображениям в карточке товара на планшете
     */
    const productImgs = document.querySelectorAll('.product__img-img');

    if(productImgs.length > 0 && window.innerWidth >= 768 && window.innerWidth <= 1240) {
        const productShortDescr = document.querySelector('.product__info-item--descr');
        const productShortDescrH = productShortDescr.offsetHeight;

        productImgs.forEach(el=> {
            el.style.height = productShortDescrH + 'px';
        });
    }
}

export default noname;