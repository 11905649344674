//Импортируем позицию лого из файла header.js
import {bigLogoModPos, smallLogoModPos} from './header.js';

function popup () {
    const popup = document.querySelector('.popup');

    if(popup) {
        const popupShow = document.querySelectorAll('.popup-show');
        const popupContent = document.querySelectorAll('.popup__inner');
        const popupClose = document.querySelectorAll('.popup-close');
        const header = document.querySelector('.header');

        //При клике на кнопку показать попап
        popupShow.forEach(el=> {
            el.addEventListener('click', function(e) {
                e.preventDefault();

                //Показываем попап
                popup.classList.add('show');

                //Получаем категорию попапа и включаем нужный контент
                const popupCat = this.dataset.popupcat.toLowerCase();

                popupContent.forEach(el=> {
                    el.classList.remove('show');
                    if(el.dataset.popupcat.toLowerCase() === popupCat) 
                        el.classList.add('show');
                });

                //Находим элементы внутри попапа для анимации
                const popupEls = popup.querySelectorAll('[data-hiddenEl]');
                
                //Сначала удаляем класс anim, а затем снова его добавляем
                if(popupEls) {
                    popupEls.forEach(el=> el.classList.remove('show'));
                    setTimeout(()=>{popupEls.forEach(el=> el.classList.add('show'))}, 400);
                }

                //Добавляем класс hide-logo шапке
                if(window.innerWidth <= 1240) 
                    header.classList.add('hide-logo');
            });
        });

        //Если пользователь закрывает попап
        popupClose.forEach(el=> {
            el.addEventListener('click', (e)=> {
                e.preventDefault();
    
                //Прячем попап и контент внутри него
                popup.classList.remove('show');
                popupContent.forEach(el=> el.classList.remove('show'));

                //Удаляем класс hide-logo в шапке
                if(window.innerWidth <= 1240) 
                    header.classList.remove('hide-logo');
            });
        });

        document.addEventListener('click', (e)=> {
            if(e.target && e.target.classList.contains('popup--transparent')) {
                //Прячем попап и контент внутри него
                popup.classList.remove('show');
                popupContent.forEach(el=> el.classList.remove('show'));

                //Удаляем класс hide-logo в шапке
                if(window.innerWidth <= 1240) 
                    header.classList.remove('hide-logo');
            }
        });
    }
}

export default popup;