//Импортируем позицию лого из файла header.js
import {bigLogoModPos, smallLogoModPos} from './header.js';

function header () {
    /**
     * Переменные общие
     */
    const navLinks = document.querySelectorAll('.nav-link');
    const mainNavLinks = document.querySelectorAll('.nav-link--main');
    const subnavContainer = document.querySelectorAll('.subnav');
    const subnavLinks = document.querySelectorAll('.subnav-link');
    const expandBtn = document.querySelectorAll('.expand-btn');

    /**
     * Переменные для десктопа
     */
    const navBacking = document.querySelector('.header__nav-backing');
    const navContainer = document.querySelector('.header__nav');

    /**
     * Настройка навигации в шапке сайта десктоп
     */
    if(window.innerWidth >= 1241) {
        //Перебираем все ссылки навигации и отслеживаем ховер
        navLinks.forEach(el=> {
            el.addEventListener('mouseenter', function(e) {
                //Снова перебираем все ссылки навигации и добавляем им opacity
                navLinks.forEach(el=> { el.classList.add('opacity') });
                //Убираем класс opacity у активной ссылки 
                this.classList.remove('opacity');

                //Прячем всю дочернюю навигацию, чтобы показать при необходимости
                subnavContainer.forEach(el=> el.classList.remove('show') );

                //Если есть дочерняя навигация
                if(this.classList.contains('nav-link--main')) {
                    //Добавляем подложку
                    navBacking.classList.add('show');

                    //Ищем дочерние элементы и показываем их
                    const parent = this.closest('.header__nav-item');
                    const subNav = parent.querySelector('.header__subnav');

                    subNav.classList.add('show');
                }

                //Если дочерней навигации нет
                if(!this.classList.contains('nav-link--main')) {
                    //Удаляем подложку
                    navBacking.classList.remove('show');
                }

            });
        });

        //Перебираем все ссылки дочерней навигации и отслеживаем ховер
        subnavLinks.forEach(el=> {
            el.addEventListener('mouseenter', function(e) {
                const parent = this.closest('.header__subnav');
                const links = parent.querySelectorAll('.header__subnav-link');

                //Перебираем все ссылки навигации и добавляем им opacity
                links.forEach(el=> { el.classList.add('opacity') });
                //Убираем класс opacity у активной ссылки 
                this.classList.remove('opacity');
            });

            el.addEventListener('mouseleave', ()=> {
                subnavLinks.forEach(el=> el.classList.remove('opacity'));
            });
        });

        //Отслеживаем событие, когда пользователь покидает область навигации
        navContainer.addEventListener('mouseleave', ()=> {
            //Прячем всю дочернюю навигацию и удаляем у ссылок opacity
            subnavContainer.forEach(el=> el.classList.remove('show') );
            subnavLinks.forEach(el=> el.classList.remove('opacity'));

            //Удаляем подложку
            navBacking.classList.remove('show');

            //Перебираем все ссылки основной навигации и удаляем opacity
            navLinks.forEach(el=> { el.classList.remove('opacity') });
        });
    }


    /**
     * Настройка отображения навигации и логотипов при клике на бургер
     */
    const burger = document.querySelector('.header__burger');
    const header = document.querySelector('.header');
    const logoBig = document.querySelector('.header__logo-mob-big');
    const logoSmall = document.querySelector('.header__logo-mob-small');

    burger.addEventListener('click', ()=> {
        //Добавляем класс nav-opened и full-logo шапке
        header.classList.toggle('nav-opened');
        header.classList.toggle('full-logo');

        //Анимируем элементы навигации внутри навигации
        const animEl = navContainer.querySelectorAll('[data-anim]');

        
        animEl.forEach((el, i)=> {
            if(header.classList.contains('nav-opened')) {
                setTimeout(()=> {
                    setTimeout(() => {
                        el.classList.add('show');
                    }, i * 100);
                }, 300);
            } else {
                el.classList.remove('show');
            }
        });


        //Если пользователь уже проскроллил страницу
        if(window.scrollY > 0) {
            //Если навигацию закрыли
            if(!header.classList.contains('nav-opened')) {
                //Возвращаем логотипы в положение, которое было до того, как навигацию открыли
                //Положение берем из файла header.js (оно меняется при скролле)
                logoBig.style.transform = bigLogoModPos;
                logoSmall.style.bottom = smallLogoModPos;

                if(smallLogoModPos.slice(0, -2) > 0 || smallLogoModPos.slice(0, -2) == 0)
                    logoSmall.style.bottom = `${0}px`;
            }
        }
    });


    /**
     * Настройка отображения дочерней навигации в шапке и футере
     * для планшетов и мобильных устройств
     */

    if(window.innerWidth <= 1240) {
        //Функция для отображения дочерней навигации
        function showSubNav (link, expandBtn, subnav, subnavListH) {
            link.classList.add('active');
            subnav.classList.add('show');
            subnav.style.height = `${subnavListH}px`;
            expandBtn.classList.add('active');
        }

        //Функция скрывающая дочернюю навигацию
        function hideSubNav (link, expandBtn, subnav) {
            link.classList.remove('active');
            subnav.classList.remove('show');
            subnav.style.height = 0;
            expandBtn.classList.remove('active');
        }

        mainNavLinks.forEach((el, i)=> {
            el.addEventListener('click', function(e) {
                const parent = this.closest('.parent');
                const expandBtn = parent.querySelector('.expand-btn');
                const subnav = parent.querySelector('.subnav');
                const subnavListH = parent.querySelector('.subnav-list').offsetHeight;

                if(!this.classList.contains('active')) {
                    e.preventDefault();
                    showSubNav (this, expandBtn, subnav, subnavListH);
                }
            });
        });

        expandBtn.forEach((el, i)=> {
            el.addEventListener('click', function(e) {
                const parent = this.closest('.parent');
                const link = parent.querySelector('.nav-link--main');
                const expandBtn = parent.querySelector('.expand-btn');
                const subnav = parent.querySelector('.subnav');
                const subnavListH = parent.querySelector('.subnav-list').offsetHeight;

                if(!this.classList.contains('active')) {
                    e.preventDefault();
                    showSubNav (link, expandBtn, subnav, subnavListH);
                } else {
                    hideSubNav (link, expandBtn, subnav);
                }
            });
        });
    }
}

export default header;