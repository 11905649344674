function slider () {
    /**
     * Подключаем слайдеры на главной
     */
    const homeSlider = document.querySelector('.home');

    if(homeSlider) {
        //Слайдер с контентом на стр десктоп версия
        if(window.innerWidth >= 1241) {
            let slider = new Swiper(homeSlider, {
                direction: "vertical",
                speed: 1000,
                mousewheel: {
                    sensitivity: 5,
                },
            });


            const slideDescr = document.querySelector('.home__header-descr');

            slider.on('slideChangeTransitionStart', function () {
                const activeSlide = slider.activeIndex + 1;

                setTimeout(()=> {
                    if (activeSlide === 1) slideDescr.innerHTML = 'Настоящие деликатесы,<br> произведенные на Юге России';
                    if (activeSlide === 2) slideDescr.innerHTML = 'Вкус, который останется незабываемым.<br> Состав, который вас приятно удивит';
                    if(activeSlide === 3) slideDescr.innerHTML = 'Что делает нашу продукцию<br> такой особенной?';
                    if(activeSlide === 4) slideDescr.innerHTML = 'Вписываем новую главу в историю<br> колбасного дела на Юге России';
                    if(activeSlide === 5) slideDescr.innerHTML = 'Порадуйте себя неповторимым вкусом<br> и ароматом деликатесов Карнеззо';
                }, 400);
            });
        }


        //Создаем слайдер с преимуществами
        let featureSlider = new Swiper(".home__feature-slider", {
            slidesPerView: 1,
            loop: true,
            navigation: {
              nextEl: ".home__feature-slider-btn--next",
              prevEl: ".home__feature-slider-btn--prev",
            },
            pagination: {
                el: ".home__feature-slider-pagination",
                clickable: true,
            },
        });


        //Настройка смены изображения при пролистывании слайдера
        //На десктопе и планшетах
        if(window.innerWidth >= 768) {
            const featuresImages = document.querySelectorAll('.home__feature-img');

            featuresImages[0].classList.add('show');
    
            featureSlider.on('slideChangeTransitionStart', function () {
                const activeSlide = featureSlider.realIndex;

                featuresImages.forEach(el=> el.classList.remove('show'));
                featuresImages[activeSlide].classList.add('show');
            });
        }
    }
    

    /**
     * Подключаем слайдер с информацией о товаре и слайдер с фото на стр товара
     * Для десктопа
     */
    let productInfoSlider = document.querySelector('.product__info-slider');

    if(productInfoSlider) {
        let sliderTxt;

        //Инициализация слайдера с инфо
        if(window.innerWidth >= 1241) {
            sliderTxt = new Swiper(productInfoSlider, {
                direction: 'vertical',
                slidesPerView: 'auto',
                speed: 1000,
                spaceBetween: 150,
                freeMode: true,
                mousewheel: {
                    eventsTarget: '.content',
                    // noMousewheelClass: 'product__info-item--kjby',
                    sensitivity: 1,
                },
                allowTouchMove: false,
            });
        }


        //Инициализация слайдера с фото
        const productImgs = document.querySelector('.product__img');
        let sliderImg;

        if(window.innerWidth >= 1241 || window.innerWidth <= 767) {
            sliderImg = new Swiper(productImgs, {
                speed: 500,
                spaceBetween: 0,
                effect: 'fade',
                loop: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                breakpoints: {
                    768: {
                        autoplay: false,
                        loop: false,
                    }
                },
                pagination: {
                    el: '.product__img-pagination',
                    clickable: true,
                    type: 'bullets',
                },
            });
        }


        if(window.innerWidth >= 1241) {
            //Настройка смены активных табов и фото
            const sliderTabs = document.querySelectorAll('.product__tabs-btn');

            //При переключении слайдов
            sliderTxt.on('slideChange', ()=> {
                sliderTabs.forEach(el=> el.classList.remove('active'));
                sliderTabs[sliderTxt.activeIndex].classList.add('active');

                sliderImg.slideTo(sliderTxt.activeIndex, 300);
            });


            //При взаимодействии с табами
            sliderTabs[0].classList.add('active');

            sliderTabs.forEach((el, i)=> {
                el.addEventListener('mouseenter', function() {
                    //Добавляем выбранному табу active класс
                    sliderTabs.forEach(el=> el.classList.remove('active'));
                    this.classList.add('active');

                    //Меняем слайд согласно номеру таба
                    sliderTxt.slideTo(i, 800);

                    //Меняем фото товара согласно номеру таба
                    sliderImg.slideTo(i, 300);
                });
            });
        }
    }
}

export default slider;