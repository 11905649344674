function tooltip() {
    const tooltipTrigger = document.querySelectorAll('.tooltip-trigger');

    if(tooltipTrigger.length > 0) {
        const tooltips = document.querySelectorAll('.tooltip');

        function showTooltip (tooltip) {
            const parent = tooltip.closest('.tooltip-parent');
            const tooltipTxt = parent.querySelector('.tooltip');

            tooltipTxt.classList.add('show');
        }

        function hideTooltip () {
            tooltips.forEach(el=> el.classList.remove('show'));
        }

        tooltipTrigger.forEach(el=> {
            el.addEventListener('mouseenter', function() {
                showTooltip (this);
            });

            el.addEventListener('mouseleave', hideTooltip);
        });


        document.body.addEventListener('click', function(e) {
            if(!e.target.classList.contains('tooltip-trigger')) {
                tooltips.forEach(el=> {
                    if(el.classList.contains('show')) {
                        hideTooltip ();
                    }
                });
            }
        });
    }
}

export default tooltip;