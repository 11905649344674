function form () {
    const formContact = document.querySelector('.form-consult');

    if(formContact) {
        const input = document.querySelectorAll('.form-input');
        const agreeCheckbox = document.querySelector('.form-agree');


        //Функция отключения кнопки отправки формы
        function disableSubmitBtn (btn) {
            btn.classList.add('disabled');
            btn.setAttribute('disabled', '');
        }

        //Функция, которая включит ее обратно
        function enableSubmitBtn (btn) {
            btn.classList.remove('disabled');
            btn.removeAttribute('disabled', '');
        }


        //Добавляем всем инпутам с проверкой класс empty, чтобы при заполнении потом убрать
        input.forEach(el=> {
            if(el.dataset.validation) {
                el.classList.add('empty');
            }
        });


        /**
         * Проверка инпутов на соответствие валидации
         */
        function checkLength (input) {
            //Проверяем остальные все импуты кроме тех, что в условии на кол-во символов внутри
            if(input.dataset.validation && input.dataset.validation != 'email' && input.dataset.validation != 'tel') {
                //Берем из дата-атрибута количество минимальных символов
                const minimalLength = input.dataset.validation;

                if(input.value.length < minimalLength) {
                    input.classList.add('error');
                } else {
                    input.classList.remove('error');

                    if(input.classList.contains('empty'))
                        input.classList.remove('empty');
                }
            }

            //Проверяем инпут емаил на наличие символа @
            if(input.dataset.validation === 'email') {
                if(!input.value.includes('@')) {
                    input.classList.add('error');
                } else {
                    input.classList.remove('error');

                    if(input.classList.contains('empty'))
                        input.classList.remove('empty');
                }
            }

            //Проверяем инпут телефон на количество символов
            if(input.dataset.validation === 'tel') {
                if(input.value.length < 9) {
                    input.classList.add('error');
                } else {
                    input.classList.remove('error');
                    
                    if(input.classList.contains('empty'))
                        input.classList.remove('empty');
                }
            }
        }


        /**
         * Используем регулярное выражение для удаления всех символов
         * кроме цифр и "+" в телефоне
         */
        function checkPhone (input) {
            let cleanedValue = input.value.replace(/[^0-9+]/g, '');
            // Обновляем значение в поле ввода
            if(input.dataset.validation === 'tel') {
                input.value = cleanedValue;
            }
        }

        input.forEach(el=> {
            /**
             * Выполняем следующее при потере фокуса с инпута
             */
            el.addEventListener('blur', function() {
                checkLength (this);

                const parent = this.closest('.form-consult');
                const inputs = parent.querySelectorAll('.form-input');
                const submitBtn = document.querySelector('.form-submit');
                const agreeCheckbox = parent.querySelector('.form-agree');

                //Создаем флаг для проверки
                let isEmpty = false;
        
                //Проходим по всем инпутам и если есть пустой инпут, меняем статус флага на true
                inputs.forEach( el => {
                    if(el.classList.contains('empty') || el.classList.contains('error')) isEmpty = true;
                });

                //Если флаг = true отключаем кнопку отправки, иначе включаем
                isEmpty ? disableSubmitBtn (submitBtn) : enableSubmitBtn (submitBtn);


                //Если пользователь не дал согласие отключаем кнопку отправки формы
                if(agreeCheckbox && !agreeCheckbox.checked) disableSubmitBtn (submitBtn);
            });

            //Выполняем следующее при заполнении инпута
            el.addEventListener('input', function() {
                //Выполняем маску телефона
                checkPhone (this);
                //Выполняем валидацию
                checkLength (this);
            });
        });


        /**
         * Проверка инпутов при клике на чекбокс в форме
         */
        agreeCheckbox.addEventListener('click', function() {
            const parent = this.closest('.form-consult');
            const inputs = parent.querySelectorAll('.form-input');
            const submitBtn = document.querySelector('.form-submit');

            if(agreeCheckbox.checked) {//Если посетитель дал согласие на обработку данных
                inputs.forEach(el=> {
                    //Проверяем заполненость всех инпутов в форме
                    if(el.value.length === 0 && el.dataset.validation) {
                        //Если какой-то из инпутов пустой, даем ему класс ошибки
                        el.classList.add('error')
                    };

                    //Создаем флаг для проверки
                    let hasError = false;
        
                    //Проходим по всем инпутам и если есть инпут с ошибкой, меняем статус флага на true
                    inputs.forEach( el => {
                        if (el.classList.contains('error')) {
                            hasError = true;
                        }
                    });

                    if (hasError) {
                        //Если есть ошибки в инпутах, отключаем кнопку отправки формы
                        disableSubmitBtn (submitBtn);
                    } else {
                        //Если нет ошибок в инпутах, возвращаем в рабочее состояние
                        enableSubmitBtn (submitBtn);
                    }
                });
            } else {//Иначе, если согласия не было отключаем кнопку отправки формы
                disableSubmitBtn (submitBtn);
            }
        });



        /**
         * Настройка инпутов для загрузки файлов
         */
        const inputFile = document.querySelector('.form-input-file');

        //Если в форме присутствует инпут для загрузки файлов
        if(inputFile) {
            //При взаимодействии с инпутом
            inputFile.addEventListener('change', function() {
                if(inputFile.value != '') {//Если загрузили в инпут файл
                    //Находим поле для загрузки файла и лейбл, который относится к инпуту
                    const parent = this.closest('.form-consult__file');
                    const label = parent.querySelector('.form-consult__file-label');

                    //Проверяем размер файла
                    //Если размер файла больше или равен 5мб
                    if(inputFile.files[0].size >= 5242880) {
                        //Добавляем класс ошибки
                        parent.classList.add('error');
                    } else {//Если файл нужного размера
                        //Проверяем есть ли класс ошибки, если есть удаляем его
                        if(inputFile.classList.contains('error'))
                            inputFile.classList.remove('error');

                        //Меняем текст лейбла
                        label.innerHTML = '<span>удалить<br> файл</span>';

                        //Добавляем класс загруженного файла и удаляем класс ошибки если есть
                        parent.classList.add('file-added');

                        if(parent.classList.contains('error'))
                            parent.classList.remove('error');

                        //При клике на "удалить файл" очищаем инпут
                        //И возвращаем в дефолтное состояние поле для загрузки файла
                        label.addEventListener('click', ()=> {
                            inputFile.value = '';
                            parent.classList.remove('file-added');
                            label.innerHTML = '<span>Загрузить<br> файл</span>';
                        });
                    }
                }
            });
        }
    }
}

export default form;