function notice () {
    const notice = document.querySelector('.notice');

    if(notice) {
        const noticeShow = document.querySelectorAll('.notice-show');
        const noticeClose = document.querySelectorAll('.notice-close');

        noticeClose.forEach(el=> {
            el.addEventListener('click', (e)=> {
                e.preventDefault();
                notice.classList.remove('show');
            });
        })

        document.addEventListener('click', (e)=> {
            if(e.target && e.target.classList.contains('notice'))
                notice.classList.remove('show');
        });

        if(noticeShow) {
            noticeShow.forEach(el=> {
                el.addEventListener('click', (e)=> {
                    e.preventDefault();
                    notice.classList.add('show');
                });
            });
        }
    }
}

export default notice;