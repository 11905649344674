function map () {
    const mapContainer = document.querySelector('#map');

    if(mapContainer) {
        ymaps.ready(function () {
            let myMap = new ymaps.Map('map', {
                center: [53.232222, 28.468162],
                zoom: 5,
                controls: []
            }, {
                searchControlProvider: 'yandex#search',
                yandexMapDisablePoiInteractivity: true
            }),
                
            //Создадим кластеризатор, вызвав функцию-конструктор.
            //Список всех опций доступен в документации.
            clusterer = new ymaps.Clusterer({
                //Через кластеризатор можно указать только стили кластеров,
                //стили для меток нужно назначать каждой метке отдельно.
                preset: 'islands#invertedVioletClusterIcons',
                //Ставим true, если хотим кластеризовать только точки с одинаковыми координатами.
                groupByCoordinates: false,
                // Опции кластеров указываем в кластеризаторе с префиксом "cluster".
                clusterDisableClickZoom: false,
                clusterHideIconOnBalloonOpen: false,
                geoObjectHideIconOnBalloonOpen: false,

                // Зададим массив, описывающий иконки кластеров разного размера.
                clusterIcons: [
                    {
                        href: '../assets/images/ico/map-group.svg',
                        size: [44, 44],
                        offset: [-22, -22]
                    }],
            }),

            //Функция возвращает объект, содержащий данные метки.
            getPointData = function (index) {},

            //Функция возвращает объект, содержащий опции метки.
            //Все опции, которые поддерживают геообъекты, можно посмотреть в документации.
            getPointOptions = function () {
                return {
                    preset: 'islands#violetIcon',
                    // Опции.
                    iconLayout: 'default#image',
                    // Своё изображение иконки метки.
                    iconImageHref: '../assets/images/ico/map-pin.svg',
                    // Размеры метки.
                    iconImageSize: [30, 30],
                    // Смещение левого верхнего угла иконки относительно
                    // её "ножки" (точки привязки).
                    iconImageOffset: [-5, -30]
                };
            },

            points = [],
            geoObjects = [];


            /*
             *Получаем все магазины из списка магазинов
            */
            const coordinatesInHtml = document.querySelectorAll('.dealers__dealer');

            //Для каждой координаты
            coordinatesInHtml.forEach(el => {
                //Удаляем пробелы после запятой
                let updatedCoordinates = el.getAttribute('data-coordinates').replaceAll(' ', '');

                //Обновляем атрибут data-coordinates в элементе
                el.setAttribute('data-coordinates', updatedCoordinates);

                //Получаем значения координат из списка магазинов
                let dataCoordinates = el.getAttribute('data-coordinates').split(',');

                //Добавляем координаты в массив
                points.push(dataCoordinates);
            });


            // Данные передаются вторым параметром в конструктор метки, опции - третьим.
            for(let i = 0, len = points.length; i < len; i++) {
                geoObjects[i] = new ymaps.Placemark(points[i], getPointData(), getPointOptions());
            }
        
            //В кластеризатор можно добавить javascript-массив меток (не геоколлекцию) или одну метку.
            clusterer.add(geoObjects);
            myMap.geoObjects.add(clusterer);


            /**
             * Создаем дополнительные настройки для планшетов и мобильных
            */
            if(window.innerWidth <= 1240) {
                //Отключаем перетаскивание карты
                myMap.behaviors.disable('drag');

                //Меняем центр карты и зум
                myMap.setCenter([55.755864, 37.617698]);
                myMap.setZoom(4);
            }


            /**
             * Записываем центр карты и зум в отдельные переменные при загрузке
             * Для переиспользования в дальнейшем
            */
            const mapCenter = myMap.getCenter();
            const mapZoom = myMap.getZoom();


            /**
             * Настраиваем отображение информации о магазине в балунах и списке
             * Информацию получаем из html
             * Список магазинов хранится в Shoplist
            */
            const shopList = document.querySelector('.dealers__shops');
            const shops = document.querySelectorAll('.dealers__dealer');

            //Показываем весь список магазинов при загрузке карты
            shops.forEach(el=> {
                const parent = el.closest('.dealers__list-item');
                parent.classList.add('show');
            });

            //Создаем функцию отображения информации о каждом магазине
            function showPartnerDetail (dataCoordinates, arrayCoordinates, targetShop) {
                //Получаем все данные магазина, который относится к выбранному маркеру
                const shopName = targetShop.dataset.shopname;
                const shopLogo = targetShop.dataset.logosrc;
                const shopAddress = targetShop.dataset.shopaddress;
                const shopContact = targetShop.dataset.shopcontact;
                const shopSchedule = targetShop.dataset.shopschedule;


                //Создаем ветку условий для вывода информации о магазине в балун
                let bottomContent = '';

                //Если магазин указал график работ
                if (shopSchedule.length > 0) {
                    //Проверяем указал ли магазин контакты
                    if (shopContact.length > 0) {
                        //Если указал, выводим в балуне информацию о графике работы и контакты
                        bottomContent += 
                        `
                            <p class="dealers__map-shop-schedule">${shopSchedule}</p>
                            <span class="dealers__map-shop-contact">${shopContact}</span>
                        `;
                    } else {//Если нет, выводим только график работ
                        bottomContent += 
                        `
                            <p class="dealers__map-shop-schedule">${shopSchedule}</p>
                        `;
                    }
                } else {//Если график работ не указан, выводим только контакты
                    if (shopContact.length > 0) {
                        bottomContent += 
                        `
                            <span class="dealers__map-shop-contact">${shopContact}</span>
                        `;
                    }
                }

                //Вставляем всю инфу в балун
                myMap.balloon.open(arrayCoordinates, {
                    contentBody: 
                    `<div class="dealers__map-shop">
                        <div class="dealers__map-shop-inner">
                            <button class="dealers__map-shop-close" type="button">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="14.9504" height="2.00967" rx="1" transform="matrix(0.707112 0.707101 -0.707112 0.707101 1.42847 0.00744629)" fill="white"/>
                                    <rect width="14.9504" height="2.01363" rx="1" transform="matrix(0.707112 -0.707101 0.707112 0.707101 0 10.5714)" fill="white"/>
                                </svg>
                            </button>
                            <div class="dealers__map-shop-header">
                                <span class="dealers__map-shop-title">${shopName}</span>
                                <img class="dealers__map-shop-logo" src="${shopLogo}">
                            </div>
                            <span class="dealers__map-shop-address">${shopAddress}</span>
                            <div class="dealers__map-shop-details">${bottomContent}</div>
                        </div>
                    </div>`
                });


                //Центруем экран по отношению к выбранному маркеру + добавляем зум
                //Добавляем небольшую задержку, т.к. балун двигает экран
                if(window.innerWidth >= 1241) {
                    setTimeout(()=> {
                        myMap.setCenter([+arrayCoordinates[0] + 0.003, +arrayCoordinates[1] - 0.014], 15);
                    }, 30);
                } else {
                    setTimeout(()=> {
                        myMap.setCenter([+arrayCoordinates[0] + 0.006, +arrayCoordinates[1]], 13);
                    }, 30);
                }
                

                //Проходим по всем магазинам
                shops.forEach(el=> {
                    //Делаем активным магазин из списка, который относится к выбранному маркеру
                    el.classList.remove('active');

                    if(el.dataset.coordinates === dataCoordinates)
                        el.classList.add('active');
                });
            }


            /*
             *При клике на маркер выполняем следующее
            */
            clusterer.events.add(['click'], function (e) {
                //Получаем элемент, по которому кликнули
                const target = e.get('target');

                //Если кликнули по маркеру
                if (typeof target.getGeoObjects == 'undefined') {
                    //Получаем координаты выбранного маркера и находим нужный магазин
                    const dataCoordinates = String(target.geometry.getCoordinates());
                    const targetShop = shopList.querySelector(`[data-coordinates="${dataCoordinates}"]`);
                    //Создаем массив из координат для переиспользования в дальнейшем
                    const arrayCoordinates = dataCoordinates.split(',');

                    //Вызываем функцию, которая показывает балун
                    showPartnerDetail (dataCoordinates, arrayCoordinates, targetShop);

                    //Получаем обертку списка магазинов и позицию магазина в данном списке
                    const shopListWrapper = document.querySelector('.dealers__shops-inner')
                    const shopPos = targetShop.offsetTop;

                    //Скроллим к магазину
                    shopListWrapper.scrollTo(0, shopPos)
                }
            });


            /**
             *Делегируем события
            */
            document.body.addEventListener('click', (e)=> {
                //Если кликнули по магазину в списке
                if(e.target && e.target.classList.contains('dealers__dealer')) {
                    const target = e.target;
                    //Получаем координаты выбранного маркера и находим нужный магазин
                    const dataCoordinates = target.dataset.coordinates;
                    const targetShop = shopList.querySelector(`[data-coordinates="${dataCoordinates}"]`);
                    //Создаем массив из координат для переиспользования в дальнейшем
                    const arrayCoordinates = dataCoordinates.split(',');

                    //Вызываем функцию отображения балуна
                    showPartnerDetail (dataCoordinates, arrayCoordinates, targetShop);

                    //Скроллим к верху карты, если планшет и мобильные
                    if (window.innerWidth <= 1240) {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        });
                    }
                }

                //Если кликнули по крестику в балуне
                if(e.target && e.target.classList.contains('dealers__map-shop-close')) {
                    //Удаляем активный класс со всех магазинов в списке
                    shops.forEach(el=> el.classList.remove('active'));
        
                    //Удаляем балун
                    myMap.balloon.close();
                }
            });


            /**
             * Настройка поиска по городам
            */
            const cityInput = document.querySelector('.dealers__cities-input');
            const cityList = document.querySelector('.dealers__cities-list');
            const resetSearch = document.querySelector('.dealers__cities-ico');

            cityInput.addEventListener('focus', ()=> {
                //Находим все города
                const cities = document.querySelectorAll('.dealers__cities-city');
                
                //Перебираем города и прячем их
                cities.forEach(el=> {
                    const parent = el.closest('.dealers__cities-item');
                    parent.style.display = 'none';
                });
                
                //Если городов меньше или равно 8, показываем список городов сразу
                // if(cities.length <= 8) {
                //     cityList.classList.add('show');

                //     cities.forEach(el=> {
                //         const parent = el.closest('.dealers__cities-item');
                //         parent.classList.add('show');
                //     });
                // }
                cityList.classList.add('show');

                cities.forEach(el=> {
                    const parent = el.closest('.dealers__cities-item');
                    parent.classList.add('show');
                });
            });

            cityInput.addEventListener('input', () => {
                //Получаем введенные символы в инпут
                const inputVal = cityInput.value.toLowerCase();
                //Получаем города
                const cities = document.querySelectorAll('.dealers__cities-city');
                let hasMatch = false;

                //Перебираем города в списке
                cities.forEach(el => {
                    //Получаем названия всех городов
                    const citiesVal = el.innerText.toLowerCase();
                    //Находим родителя городов
                    const parent = el.closest('.dealers__cities-item');

                    //Если название города совпадает с введенными в инпуте символами
                    if (citiesVal.includes(inputVal)) {
                        //Показываем город
                        parent.classList.add('show');
                        hasMatch = true;
                    } else {//Иначе
                        //Прячем город
                        parent.classList.remove('show');
                    }
                });

                if (hasMatch && cityInput.value.length >= 2) {//Если пользователь ввел больше 2-х символов
                    //Показываем список городов и добавляем инпуту активный класс
                    cityList.classList.add('show');
                    cityInput.classList.add('active');
                } else {//Если инпут пустой
                    //Прячем список городов и удаляем у инпута активный класс
                    cityList.classList.remove('show');
                    cityInput.classList.remove('active');
                }
            });


            //Создаем пустые переменные центра города и зума
            let townCenter;
            let townZoom;

            //При клике по городу взаимодействуем со страницей
            document.body.addEventListener('click', (e) => {
                if (e.target && e.target.classList.contains('dealers__cities-city')) {
                    // Отключаем стандартное поведение кнопки
                    e.preventDefault();
            
                    // Закрываем список городов
                    cityList.classList.remove('show');
                    // Вставляем в инпут поиска городов выбранный город и добавляем ему класс active
                    cityInput.value = e.target.dataset.city;
                    cityInput.classList.add('active');

                    //Удаляем активный класс со всех магазинов в списке и удаляем балун
                    shops.forEach(el=> el.classList.remove('active'));
                    myMap.balloon.close();
            
                    // Создаем массив для хранения значения дата атрибута shopName отображаемых магазинов
                    const visibleShops = [];
            
                    // Перебираем все магазины
                    shops.forEach(el => {
                        // Получаем родителя
                        const parent = el.closest('.dealers__list-item');
                        // Прячем все магазины в списке
                        parent.classList.remove('show');
            
                        // Если в городе есть магазины
                        if (el.dataset.city.toLowerCase() === e.target.dataset.city.toLowerCase()) {
                            // Показываем их
                            parent.classList.add('show');
                            // Добавляем магазин в массив отображаемых магазинов
                            visibleShops.push(el.dataset.shopname);
                        }
                    });

                    // Проходим по всем кнопкам фильтрации по категории (по названию магазина)
                    categoryBtn.forEach(btn => {
                        const parent = btn.closest('.dealers__category-item');
                        // Прячем все кнопки категорий кроме первой
                        if(btn.dataset.shopname != 'all') 
                            parent.style.display = 'none';
            
                        // Если название магазина совпадает с одной из видимых категорий, показываем кнопку
                        if (visibleShops.includes(btn.dataset.shopname)) 
                            parent.style.display = 'block';
                    });

                    //Показываем все маркеры на карте
                    filterPlacemarks("all");
            
                    // Получаем координаты выбранного города
                    const coordinates = e.target.dataset.coordinates.split(',');

                    //Открываем на карте выбранный город
                    //И записываем центр города в ранее созданную переменные
                    if(window.innerWidth >= 1241) {
                        myMap.setCenter([+coordinates[0], +coordinates[1] - 0.4], 10);
                        townCenter = [+coordinates[0], +coordinates[1] - 0.4];
                        townZoom = 10;
                    } else {
                        myMap.setCenter([+coordinates[0], +coordinates[1]], 10);
                        townCenter = [+coordinates[0], +coordinates[1]];
                        townZoom = 10;
                    }
            
                    // Сбрасываем выбранные категории магазинов и возвращаем класс active первой кнопке
                    categoryBtn.forEach(el => el.classList.remove('active'));
                    categoryBtn[0].classList.add('active');
                }
            });


            //При потере фокуса с инпута
            cityInput.addEventListener('blur', ()=> {
                if(cityInput.value === '') {
                    // Сбрасываем выбранные категории магазинов и возвращаем класс active первой кнопке
                    categoryBtn.forEach(el => el.classList.remove('active'));
                    categoryBtn[0].classList.add('active');

                    //Отображаем все категории (названия магазинов) снова
                    categoryBtn.forEach(btn => {
                        const parent = btn.closest('.dealers__category-item');
                        parent.style.display = 'block';
                    });

                    //Показываем весь список магазинов
                    shops.forEach(el=> {
                        const parent = el.closest('.dealers__list-item');
                        parent.classList.add('show');
                    });

                    //Прячем список городов
                    // setTimeout(()=> {
                    //     cityList.classList.remove('show');
                    // }, 100);
                }
            });


            /**
             * Настраиваем фильтрацию по категориям магазинов
            */
            const categoryBtn = document.querySelectorAll('.dealers__category-btn');

            categoryBtn.forEach(el => {
                el.addEventListener('click', function (e) {
                    // Добавляем класс active выбранному фильтру
                    categoryBtn.forEach(el => el.classList.remove('active'));
                    e.target.classList.add('active');
            
                    // Получаем значение категории (названия магазинов)
                    const categoryName = e.target.dataset.shopname.toLowerCase();
            
                    // Получаем выбранный город
                    const targetCity = cityInput.value.toLowerCase();
            
                    // Перебираем все магазины
                    shops.forEach(el => {
                        // Получаем родителя
                        const parent = el.closest('.dealers__list-item');
            
                        // Прячем все магазины в списке
                        parent.classList.remove('show');
            
                        // Если ранее была выборка по городам
                        if (targetCity !== '') {
                            // Показываем только нужные магазины, которые совпадают и по категории и по городу
                            if (el.dataset.shopname.toLowerCase() === categoryName 
                                && el.dataset.city.toLowerCase() === targetCity) {
                                parent.classList.add('show')
                            }

                            //Возвращаемся к центру города обратно
                            //И устанавливаем ранее установленный зум
                            myMap.setCenter(townCenter);
                            myMap.setZoom(townZoom);
                        }
            
                        // Если ранее выборки по городам не было
                        if (targetCity === '') {
                            // Показываем все магазины, фильтруя их только по категориям
                            if (el.dataset.shopname.toLowerCase() === categoryName) {
                                parent.classList.add('show')
                            }

                            //Возвращаемся к центру карты и зум по-дефолту
                            myMap.setCenter(mapCenter);
                            myMap.setZoom(mapZoom);
                        }
            
                        // Если пользователь сбросил фильтр по категориям
                        if (categoryName === 'all') {
                            // Проверяем была ли выборка по городам
                            if (targetCity !== '') {
                                // Если была показываем все магазины в выбранном городе
                                if (el.dataset.city.toLowerCase() === targetCity)
                                    parent.classList.add('show');
                            } else { // Если выборки не было показываем магазины во всех городах
                                parent.classList.add('show');
                            }
                        }
                    });
            
                    // Фильтруем метки на карте
                    filterPlacemarks(categoryName);

                    //Удаляем балун на карте и удаляем класс active с магазинов
                    shops.forEach(el=> el.classList.remove('active'));
                    myMap.balloon.close();
                });
            });
            

            //Создаем функцию фильтрации маркеров на карте (по названию/категории магазина)
            function filterPlacemarks(shopName) {
                // Удаляем все метки из кластеризатора
                clusterer.removeAll();
            
                // Собираем координаты меток, которые нужно оставить
                const filteredCoordinates = [];
            
                // Если выбрана категория "all", добавляем все координаты
                if (shopName === "all") {
                    shops.forEach(el => {
                        const coordinates = el.getAttribute('data-coordinates').split(',').map(coord => parseFloat(coord));
                        filteredCoordinates.push(coordinates);
                    });
                } else {
                    shops.forEach(el => {
                        if (el.getAttribute('data-shopname').toLowerCase() === shopName.toLowerCase()) {
                            const coordinates = el.getAttribute('data-coordinates').split(',').map(coord => parseFloat(coord));
                            filteredCoordinates.push(coordinates);
                        }
                    });
                }
            
                // Фильтруем метки
                const filteredGeoObjects = geoObjects.filter((geoObject) => {
                    const coordinates = geoObject.geometry.getCoordinates().map(coord => parseFloat(coord));
                    const matched = filteredCoordinates.some(coord => coord[0] === coordinates[0] && coord[1] === coordinates[1]);
                    return matched;
                });
            
                // Добавляем отфильтрованные метки в кластеризатор
                clusterer.add(filteredGeoObjects);
            }
            
            // Изначально отображаем все метки
            filterPlacemarks("all");
            

            /**
             * Настраиваем сброс данных в поиске по городам
            */
            resetSearch.addEventListener('click', ()=> {
                //Очищаем инпут поиска и убираем класс active
                cityInput.value = "";
                cityInput.classList.remove('active');

                //Сбрасываем выбранные категории магазинов и возвращаем класс active первой кнопке
                categoryBtn.forEach(el=> el.classList.remove('active'));
                categoryBtn[0].classList.add('active');

                //Отображаем все категории (названия магазинов)
                categoryBtn.forEach(btn => {
                    const parent = btn.closest('.dealers__category-item');
                    parent.style.display = 'block';
                });

                //Прячем список городов
                cityList.classList.remove('show');

                //Показываем весь список магазинов
                shops.forEach(el=> {
                    const parent = el.closest('.dealers__list-item');
                    parent.classList.add('show');
                });

                //Показываем все маркеры на карте
                filterPlacemarks("all");

                //Возвращаемся к центру карты и зум по-дефолту
                myMap.setCenter(mapCenter);
                myMap.setZoom(mapZoom);
            });

            

            /**
             * Настраиваем зум карты при клике на контроллеры
            */
            const zoomBtn = document.querySelectorAll('.dealers__map-controls-btn');
            
            zoomBtn.forEach(el=> {
                el.addEventListener('click', function(e) {
                    let zoom = myMap.getZoom();

                    this.classList.contains('dealers__map-controls-btn--plus') ? myMap.setZoom(zoom += 1) : myMap.setZoom(zoom -= 1);
                });
            });
        });
    }
}

export default map;